<template>
	<main id="main" class="site-main">
		<ChannelList :channelList="channelList" />
	</main>
</template>
<script>
import { core } from "../../config/pluginInit";
import ChannelList from "./Channel/Channels.vue";

export default {
	name: "Channels",
	components: {
		ChannelList,
	},
	mounted() {
		core.index();
	},
	data() {
		return {
			channelList: [],
		};
	},
	async created() {
		// get data from the API
		// this.channelList = data.channels;
	},
};
</script>
<style lang="less" scoped></style>
